import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

interface Project {
  id: number;
  title: string;
  image: string;
  path: string;
}

const projects: Project[] = [
  { id: 1, title: 'Thomson Carter', image: '/assets/TC1.webp', path: '/thomson-carter' },
  { id: 2, title: 'Nebulox', image: '/assets/Open.jpg', path: '/nebulox' },
  { id: 3, title: 'Poster Design', image: '/assets/Poster-4.jpeg', path: '/poster-design' },
  { id: 4, title: 'Web Desgin', image: '/assets/Primitivo-1.webp', path: '/web-design' },
  
];

const WorkPage: React.FC = () => {
  const [hoveredProject, setHoveredProject] = useState<number | null>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const projectRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  useEffect(() => {
    if (imageRef.current) {
      if (hoveredProject !== null) {
        gsap.to(imageRef.current, {
          width: '33vw',
          height: '33vw',
          opacity: 1,
          x: '50%',
          y: '50%',
          xPercent: -50,
          yPercent: -50,
          duration: 0.6,
          ease: 'power2.out'
        });
      } else {
        gsap.to(imageRef.current, {
          opacity: 0,
          duration: 0.6,
          ease: 'power2.in'
        });
      }
    }
  }, [hoveredProject]);

  return (
    <div className="flex items-center justify-center h-screen bg-paper relative overflow-hidden">
      <div className="text-center z-10">
        {projects.map((project, index) => (
          <React.Fragment key={project.path}>
            <Link
              ref={el => projectRefs.current[index] = el}
              to={`/projects${project.path}`}
              className="text-xl text-darkYellow hover:text-highlight transition-colors duration-200 relative z-10"
              onMouseEnter={() => setHoveredProject(project.id)}
              onMouseLeave={() => setHoveredProject(null)}
            >
              {project.title}
            </Link>
            {index < projects.length - 1 && <span className="text-darkYellow mx-2">-</span>}
          </React.Fragment>
        ))}
      </div>
      <div 
        ref={imageRef}
        className="fixed bg-center bg-cover pointer-events-none"
        style={{ 
          backgroundImage: hoveredProject !== null ? `url(${projects.find(p => p.id === hoveredProject)?.image})` : 'none',
          zIndex: 0 
        }}
      />
    </div>
  );
};

export default WorkPage;