import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import Footer from '../Footer';

const PosterDesign: React.FC = () => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});
  const iconRefs = useRef<{ [key: string]: HTMLSpanElement | null }>({});

  const toggleSection = (section: string) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    Object.entries(iconRefs.current).forEach(([title, ref]) => {
      if (ref) {
        gsap.to(ref, {
          rotation: openSections[title] ? 180 : 0,
          duration: 0.3,
          ease: "power2.inOut"
        });
      }
    });
  }, [openSections]);

  return (
    <div className="bg-paper">
      <div className='flex flex-col md:flex-row'>
        {/* Left column */}
        <div className="w-full md:w-1/2 p-4 px-12">
          <h1 className="text-subheading mb-6">POSTER DESIGN</h1>
          <div className="mb-4">
            <h2 
              className="text-xl text-paragraph pb-4 pt-10 cursor-pointer flex justify-between items-center"
              onClick={() => toggleSection('Explanation 1')}
            >
              Design
              <span ref={el => iconRefs.current['Explanation 1'] = el}>
                {openSections['Explanation 1'] ? '−' : '+'}
              </span>
            </h2>
            <div className="h-40 overflow-hidden"> {/* Fixed height container */}
              <div className={`transition-opacity duration-300 ${openSections['Explanation 1'] ? 'opacity-100' : 'opacity-0'}`}>
                <p className='text-small leading-relaxed max-w-md'>In this project, several posters were created using Blender 
                  for 3D design, along with After Effects and Photoshop for 
                  final compositing. The posters were designed to capture the 
                  vibrant and energetic atmosphere of electronic music events, 
                  combining detailed 3D elements with striking visual effects. 
                  The integration of 3D techniques with traditional poster 
                  design resulted in bold, eye-catching promotional materials.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h2 
              className="text-xl text-paragraph pb-4 pt-10 cursor-pointer flex justify-between items-center"
              onClick={() => toggleSection('Explanation 2')}
            >
              Promotion
              <span ref={el => iconRefs.current['Explanation 2'] = el}>
                {openSections['Explanation 2'] ? '−' : '+'}
              </span>
            </h2>
            <div className="h-40 overflow-hidden"> {/* Fixed height container */}
              <div className={`transition-opacity duration-300 ${openSections['Explanation 2'] ? 'opacity-100' : 'opacity-0'}`}>
                <p className='text-small leading-relaxed max-w-md'>To further enhance the promotion, motion reels were 
                  developed using After Effects, incorporating dynamic 
                  animations of the 3D elements. Typography was carefully 
                  selected to complement the futuristic and energetic 
                  aesthetic of electronic music, with bold fonts enhancing 
                  the overall impact. This project showcases the ability 
                  to merge 3D design, motion graphics, and typography to 
                  create visually compelling content for music event 
                  promotion.</p>
              </div>
            </div>
          </div>
          {/* Vertical videos */}
          <div className="flex flex-col md:flex-row mb-6">
            <video className="w-full md:w-1/2 h-auto md:h-1/2 mb-4 md:mb-0 md:mr-4" autoPlay loop muted>
              <source src="/assets/April.mp4" type="video/mp4" />
            </video>
            <video className="w-full md:w-1/2 h-auto md:h-1/2" autoPlay loop muted>
              <source src="/assets/June.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        {/* Right column */}
        <div className="w-full md:w-1/2 p-4 md:ml-12">
          {/* Logo */}
          <img src="/assets/Fluxo full black.png" alt="Logo" 
          className="w-full h-auto mt-6 mb-6"/>

          {/* Square images */}
          <div className="flex flex-col md:flex-row mb-6">
            <img src="/assets/Poster-3.jpg" alt="Square 1" className="w-full md:w-1/2 h-auto md:h-1/2 object-cover mb-2 md:mb-0 md:mr-2" />
            <img src="/assets/Poster-4.jpeg" alt="Square 2" className="w-full md:w-1/2 h-auto md:h-1/2 object-cover md:ml-2" />
          </div>
          <div className="flex flex-col md:flex-row mb-6">
            <img src="/assets/Poster-1.jpeg" alt="Square 1" className="w-full md:w-1/2 h-auto md:h-1/2 object-cover mb-2 md:mb-0 md:mr-2" />
            <img src="/assets/Poster-2.jpeg" alt="Square 2" className="w-full md:w-1/2 h-auto md:h-1/2 object-cover md:ml-2" />
          </div>
          
          {/* 1080x1350 images */}
          {/* <div className="flex flex-col md:flex-row mb-6">
            <img src="/path-to-image-1.jpg" alt="Image 1" className="w-full md:w-1/2 h-auto md:h-[1350px] object-cover mb-2 md:mb-0 md:mr-2" />
            <img src="/path-to-image-2.jpg" alt="Image 2" className="w-full md:w-1/2 h-auto md:h-[1350px] object-cover md:ml-2" />
          </div>
          <div className="flex flex-col md:flex-row">
            <img src="/path-to-image-3.jpg" alt="Image 3" className="w-full md:w-1/2 h-auto md:h-[1350px] object-cover mb-2 md:mb-0 md:mr-2" />
            <img src="/path-to-image-4.jpg" alt="Image 4" className="w-full md:w-1/2 h-auto md:h-[1350px] object-cover md:ml-2" />
          </div> */}
        </div>
      </div>
      <div className='md:mt-60 sm:mt-4'>
        <Footer />
      </div>
    </div>
  );
};

export default PosterDesign;
