import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import WorkPage from './components/WorkPage';
import ThomsonCarter from './components/pages/ThomsonCarter';
import Nebulox from './components/pages/Nebulox';
import PosterDesign from './components/pages/PosterDesign';
import WebDesign from './components/pages/WebDesign';

function App() {
  return (
    <Router>
      <div className="bg-paper font-rothwood font-normal">
        <Navbar />
        <div className="container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/work" element={<WorkPage />} />
            <Route path="/" element={<WorkPage />} />
            <Route path="/projects/thomson-carter" element={<ThomsonCarter />} />
            <Route path="/projects/nebulox" element={<Nebulox />} />
            <Route path="/projects/poster-design" element={<PosterDesign />} />
            <Route path="/projects/web-design" element={<WebDesign />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;


