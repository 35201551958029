import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import Footer from '../Footer';

const ThomsonCarter: React.FC = () => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});
  const iconRefs = useRef<{ [key: string]: HTMLSpanElement | null }>({});

  const toggleSection = (section: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    Object.entries(iconRefs.current).forEach(([title, ref]) => {
      if (ref) {
        gsap.to(ref, {
          rotation: openSections[title] ? 180 : 0,
          duration: 0.3,
          ease: 'power2.inOut',
        });
      }
    });
  }, [openSections]);

  return (
    <div className="bg-paper">
      <h1 className="text-subheading py-12 px-12 mx-8">THOMSON CARTER</h1>
      <div className="flex flex-col md:flex-row h-auto md:h-screen ">
        {/* Left column: Vertical video */}
        <div className="md:w-1/3 mx-8 md:mx-20 order-1">
          <video className="w-full h-full object-cover " autoPlay loop muted>
            <source src="/assets/ThomsonCarter.mp4" type="video/mp4" />
          </video>
          <div>
            <img src="/assets/down0074.jpg" alt="Image 1" className="w-full object-cover mt-4" />
            <img src="/assets/covered.jpg" alt="Image 2" className="w-full object-cover mt-4" />
          </div>
          <div>
            <img src="/assets/wrappedBottle.jpg" alt="Image 1" className="w-full object-cover mt-4" />
          </div>
        </div>

        {/* Right column */}
        <div className="md:w-1/2 mx-8 md:mx-0 flex flex-col justify-between order-1 md:order-2">
          {/* Project explanation */}
          <div className="p-6">
            <div className="mb-4">
              <h2
                className="text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('Project Overview')}
              >
                3D Bottle Modeling and Label Design
                <span ref={(el) => (iconRefs.current['Project Overview'] = el)}>
                  {openSections['Project Overview'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['Project Overview'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  For Thomson Carter, a brand introducing a new product, the project began by modeling a 3D bottle in Blender. The bottle was designed from scratch, with every detail carefully sculpted to match the product specifications. In addition to the bottle model, a mockup for the label was created, adhering to the brand's design guidelines and ensuring that the colors, typography, and overall visual identity aligned with the product's marketing brief.
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h2
                className="text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('Design Approach')}
              >
                Advanced Simulations and Visual Effects
                <span ref={(el) => (iconRefs.current['Design Approach'] = el)}>
                  {openSections['Design Approach'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['Design Approach'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  To add dynamic elements to the product visualization, Houdini was used to create advanced simulations, including liquid effects and other visual enhancements. These simulations added depth and realism to the promotional videos. Blender's lighting tools were used to illuminate the product, ensuring that the reflections and shadows complemented the material properties of the bottle, making the renders visually striking and polished.
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h2
                className="text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('Technical Implementation')}
              >
                Post-Production and Animation for Promotional Use
                <span ref={(el) => (iconRefs.current['Technical Implementation'] = el)}>
                  {openSections['Technical Implementation'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['Technical Implementation'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  Once the modeling and simulations were complete, the project moved into the post-production phase. After Effects and Photoshop were used for color grading, visual effects, and final touch-ups to the still renders and animations. The resulting product was a series of high-quality 3D renders and promotional animations that captured the essence of the product, aligning perfectly with the brand's vision and design brief. These assets were used across various promotional platforms to enhance the product launch.
                </p>
              </div>
            </div>
          </div>

          {/* Two images */}
          <div className="flex flex-col md:flex-row mb-6">
            <img src="/assets/TC1.webp" alt="Image 1" className="md:w-1/2 object-cover mx-2 mb-4" />
            <img src="/assets/TC2.webp" alt="Image 2" className="md:w-1/2 object-cover mx-2 mb-4" />
          </div>

          {/* Internal Visualizations */}
          <div className="flex flex-col m-8">
            <div className="mb-4">
              <h2
                className=" text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('BottleDetails')}
              >
                Internal Product Visualisations
                <span ref={(el) => (iconRefs.current['BottleDetails'] = el)}>
                  {openSections['BottleDetails'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['BottleDetails'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  In addition to the public-facing promotional materials for Thomson Carter's upcoming launch, internal product visualisations were developed specifically for presentations and strategy meetings. Detailed, realistic 3D renders were created to ensure stakeholders had a comprehensive view of the product before its release. This allowed for informed decision-making on branding, packaging, and distribution strategies.
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h2
                className="text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('AdvancedSimulations')}
              >
                Interactive 3D Visuals for Cross-Team Collaboration
                <span ref={(el) => (iconRefs.current['AdvancedSimulations'] = el)}>
                  {openSections['AdvancedSimulations'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['AdvancedSimulations'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  The internal visualisations also facilitated cross-team collaboration by providing marketing, design, and product teams with a shared resource for feedback and refinement. Interactive 3D models were created to allow stakeholders to explore the product from multiple angles and perspectives, enhancing communication and streamlining the review process.
                </p>
              </div>
            </div>
          </div>

          {/* Two small videos */}
          <div className="flex mb-6 mt-12">
            <video className="w-1/2 object-cover mx-2" autoPlay loop muted>
              <source src="/assets/TestTC.mp4" type="video/mp4" />
            </video>
            <video className="w-1/2 object-cover mx-2" autoPlay loop muted>
              <source src="/assets/TestTC-2.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center md:mt-[120rem]
      sm:mt-[20rem]">
        <Footer />
      </div>
    </div>
  );
};

export default ThomsonCarter;


