import React from 'react';

interface FooterProps {
  githubLink?: string;
}

const Footer: React.FC<FooterProps> = ({ githubLink }) => {
  return (
    <footer className="h-16 flex-shrink-0 flex items-center 
    justify-center bg-paper mt-20 ">
      <div className="text-center">
        {githubLink && (
          <a 
            href={githubLink} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-small underline mb-2 block"
          >
            Access the GitHub Repository
          </a>
        )}
        <p className="text-small mt-6">by ARIAS</p>
      </div>
    </footer>
  );
};

export default Footer;
